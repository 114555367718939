import React, { useContext, useEffect, useRef, useState } from "react";

import { useParams } from "react-router-dom";
import ReactGa4 from "react-ga4";
import videojs from "video.js";
import "video.js/dist/video-js.css";

/* Material UI Components */
import { Box, CircularProgress, Container, Typography } from "@mui/material";
// import { ContentCopy } from "@mui/icons-material";
import { grey } from "@mui/material/colors";

/* Context */
import { Video } from "../../context/Video";
import "./styles/VideoMonitor.scss";

const VideoPlayer = () => {
  const videoRef = useRef(null); // Reference to the <video> element
  const playerRef = useRef(null); // Reference to the Video.js instance
  const globalVideo = useContext(Video); // Access globalVideo context
  const param = useParams(); // Retrieve parameters from the route

  // State for current playback time and quality
  // eslint-disable-next-line no-unused-vars
  const [time, setTime] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [quality, setQuality] = useState(null);

  const configDefaults = {
    autoplay: true,
    controls: true,
    muted: true, // Unmute video unless autoplay restrictions require mute
    preload: "auto",
    responsive: true,
    fluid: true, // Makes the player responsive
    aspectRatio: "16:9", // Maintain a 16:9 aspect ratio
  };

  // Initialize Video.js Player
  useEffect(() => {
    if (videoRef.current) {
      // Dispose of any existing player instance
      if (playerRef.current) {
        playerRef.current.dispose();
      }

      // Create a new player instance
      playerRef.current = videojs(videoRef.current, configDefaults, () => {
        console.log("Video.js player initialized.");
      });

      // Add event listeners for time and quality
      playerRef.current.on("timeupdate", () => {
        setTime({
          currentTime: playerRef.current.currentTime(),
        });
      });

      playerRef.current.on("loadeddata", () => {
        const videoHeight = playerRef.current.videoHeight();
        setQuality({
          level: videoHeight ? `${videoHeight}p` : "Unknown",
        });
      });

      playerRef.current.on("error", (e) => {
        console.error("Video.js Error:", e);
      });
    }

    return () => {
      // Dispose of player instance on cleanup
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  // Handle Video Source Changes
  useEffect(() => {
    if (playerRef.current && globalVideo.stream.url) {
      playerRef.current.src({
        src: globalVideo.stream.url,
        type: "application/x-mpegURL", // Assuming HLS streams
      });

      playerRef.current.ready(() => {
        playerRef.current.play().catch((err) => {
          console.warn("Playback failed. This might be due to autoplay restrictions.", err);
        });
      });
    }
  }, [globalVideo.stream.url]);

  // Update Page Title
  useEffect(() => {
    if (globalVideo.station.station && globalVideo.stream.url) {
      const distroTitle = param.distro?.toUpperCase().replace(/_/g, " ") || globalVideo.stream.distro.toUpperCase().replace(/_/g, " ");

      document.title = `${globalVideo.station.station.toUpperCase()} ${distroTitle} Video Monitor | SyncMon | Gray Digital`;
      ReactGa4.send({ hitType: "pageview" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalVideo.station.station, globalVideo.stream.url]);

  // const onCopy = (copyText) => {
  //   navigator.clipboard
  //     .writeText(copyText)
  //     .then(() => alert("Successfully copied: " + copyText))
  //     .catch(() => alert("Something went wrong"));
  // };

  return (
    <Box className="videoplayer" component="main" sx={{ flexGrow: 1 }}>
      {globalVideo.stream && globalVideo.stream.url ? (
        <Box className="station">
          <Box className="player-container">
            <Box className="player-columns player">
              <div data-vjs-player style={{ minWidth: 600 }}>
                <video
                  ref={videoRef}
                  className="video-js vjs-default-skin"
                  style={{ marginBottom: "1rem", borderRadius: "0.5rem", overflow: "hidden" }}
                />
              </div>
            </Box>
            <Box className="player-columns">
              {globalVideo.stream.type === "vod" && (
                <Box className="vodMeta">
                  <Typography className="title">
                    {globalVideo.stream.title} &nbsp;
                    <span>({Math.round(globalVideo.stream.duration / 1000 / 60)}m)</span>
                  </Typography>
                  <Typography className="description">{globalVideo.stream.desc}</Typography>
                  {globalVideo.stream.adbreaks && globalVideo.stream.adbreaks.length > 0 && (
                    <Typography className="title" sx={{ marginTop: "0.5rem" }}>
                      Ad Breaks
                    </Typography>
                  )}
                  {globalVideo.stream.adbreaks?.map((val, key) => (
                    <Box key={"adbreak_" + key} className="adbreak" sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                      <Typography className="adbreakTitle" sx={{ fontSize: "0.75rem" }}>
                        {key + 1}. Ad Break
                      </Typography>
                      <Typography className="adbreakTime" sx={{ fontSize: "0.75rem" }}>
                        @ {Math.round(val.offset / 1000 / 60)}m {Math.round((val.offset / 1000) % 60)}s
                      </Typography>
                    </Box>
                  ))}
                </Box>
              )}
              {time && (
                <Box className="metaTable">
                  <Typography variant="h5">Playback Information</Typography>
                  <Box>
                    <Typography>Station:</Typography>
                    <Typography>{globalVideo.station.station.toUpperCase()}</Typography>
                  </Box>
                  <Box>
                    <Typography>Position:</Typography>
                    <Typography>{time.currentTime.toFixed(2)} seconds</Typography>
                  </Box>
                  <Box>
                    <Typography>Quality:</Typography>
                    <Typography>{quality ? quality.level : "Loading..."}</Typography>
                  </Box>
                  {globalVideo.station.note && (
                    <Box
                      style={{
                        justifyContent: "flex-start",
                        gap: "0.5rem",
                        fontStyle: "italic",
                        marginTop: "1rem",
                        color: grey["A700"],
                      }}
                    >
                      <Typography style={{ fontWeight: 900 }}>Note:</Typography>
                      <Typography>
                        {globalVideo.station.note} (Information current as of {globalVideo.station.noteDate})
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      ) : (
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
          <Typography variant="body1" sx={{ marginTop: "1rem", color: "#36c" }}>
            Loading
          </Typography>
        </Container>
      )}
    </Box>
  );
};

export default VideoPlayer;
